import {STATUS} from "../../pages/new-tracking/app/enum/status";
import "../type"

class TrackUseCase {
    /**
     *
     * @param xDay {number}
     * @param historyPODTime {null|string}
     * @returns {boolean}
     */
    isPODMoreThanXDays(xDay = 20, historyPODTime = undefined) {
        if (!historyPODTime || historyPODTime === "") {
            return false
        }
        const pod_time = new Date(historyPODTime);
        const date_now = new Date();
        const timeDiff = Math.abs(date_now.getTime() - pod_time.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return diffDays > xDay
    }

    /**
     *
     * @param histories {TrackHistory|TrackHistoryMapper[]}
     * @returns {TrackHistory|TrackHistoryMapper[]}
     */
    mapperHistories(histories) {
        /** @type {TrackHistoryMapper[]} */
        const listObj = []
        Object.keys(histories).forEach(key => {
            /** @type {String} */
            let newName = ""

            if (key.endsWith("location")) {
                const name = key.split("location")[0].split("")
                name.pop()
                newName = name.join("")
            }
            const desc = histories[newName + "_" + "remarks"] || ""
            if (newName !== '' && (desc && desc !== '')) {
                listObj.push({
                    name: newName,
                    location: histories[newName + "_" + "location"] || "",
                    time: histories[newName + "_" + "time"] || "",
                    desc: desc,
                    enum: this._createEnumAlias(newName),
                    level: this._createLevelStatus(newName)
                })
            }
        })
        listObj.sort((a, b) => b.level - a.level)
        return listObj
    }

    /**
     *
     * @param statusName {string}
     * @returns {string|undefined}
     * @private
     */
    _createEnumAlias(statusName) {
        for (const key in STATUS) {
            if (STATUS[key].name === statusName) {
                return key
            }
        }
        return undefined
    }

    /**
     *
     * @param statusName {string}
     * @returns {number}
     * @private
     */
    _createLevelStatus(statusName) {
        for (const key in STATUS) {
            if (STATUS[key].name === statusName) {
                return STATUS[key].value
            }
        }
        return -1
    }

}

export default new TrackUseCase()