import CheckShippingPriceAlgoRepository from '../api/checkShippingPriceAlgo';

class CheckShippingPriceAlgoUseCase {
    constructor() {
        this.api = new CheckShippingPriceAlgoRepository();
    }

    /**
     *
     * @param payload {RequestCheckPriceAlgo}
     * @returns {Promise<{data: (*|Array<ResponseCheckPriceAlgo>), err: null}|{data: null, err: (*|null)}>}
     */
    async requestShippingPrice(payload) {
        return await this.api.requestShippingPrice(payload);
    }

    /**
     *
     * @param payload {{data: RequestCheckPriceBulkyAlgo[], is_new_cod: boolean}}
     * @returns {Promise<{data: ResponseBulkyCheckPriceAlgo[] | null, total: null, parcel_point: null, err: *}>}
     */
    async bulkyCheckShippingPrice(payload) {
        return await this.api.bulkyCheckShippingPrice(payload);
    }

}

export default new CheckShippingPriceAlgoUseCase();
