import Vue from "vue";
import Vuex from "vuex"
import checkPrice from "./modules/checkPrice";
import tracking from "./modules/tracking";
import agent from "./modules/agent";
import npos from './modules/npos';
import claim from "./modules/claim";
import products from "./modules/product";
import career from "./modules/career";
import businessInquiry from "./modules/businessInquiry";
import order from "./modules/order";
import auth from "./modules/auth";
import game from "./modules/game";

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        checkPrice,
        tracking,
        agent,
        npos,
        claim,
        products,
        career,
        businessInquiry,
        order,
        auth,
        game,
    }
})
