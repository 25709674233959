import CareerRepository from "../api/career";
import "@/pages/new-career/app/types";

class CareerUseCase {
  constructor() {
    /** @type {CareerRepository} */ 
    this.api = new CareerRepository()
  }

  /**
   * @param {string} query query param
   * @param {number} page page pagination
   * @return {Promise<{data: CareerListResponse, err: ErrorResponse}>}
   * */ 
  async getAll(query, page = 1) {
    let queryStr = `page=${page}&${query}&status=ACTIVE`

    const {data, err} = await this.api.getAll(queryStr)
    return {data, err}
  }

  /**
   * @param {number} id career id
   * @return {Promise<{data: Career, err: ErrorResponse}>}
   * */ 
  async getDetail(id) {
    const {data, err} = await this.api.getDetail(id)
    return {data, err}
  }

  async submitCandidate(payload) {
    const formData = new FormData()
    Object.keys(payload).forEach((key) => {
      if (payload[key].type === 'dropdown') {
        formData.append(key, payload[key].value[0].value)
      } else if (payload[key].type === 'date') {
        formData.append(key, new Date(payload[key].value).toISOString())
      } else if (key === 'candidate_phone_number') {
        formData.append(key, `62${payload[key].value}`)
      } else {
        formData.append(key, payload[key].value)
      }
    })
    const {data, err} = await this.api.submitCandidate(formData)
    return {data, err}
  }

  async getEmployeeBranding() {
    const {data, err} = await this.api.getEmployeeBranding();
    return {data, err}
  }

  async getLocation() {
    const {data, err} = await this.api.getLocation();
    return {data: data.data.map(a => a.province_name).sort((a, b)=> a.localeCompare(b)), err}
  }

  async getDivision() {
    const {data, err} = await this.api.getDivision();
    return {data: data.data.map(a => a.division_name).sort((a, b)=> a.localeCompare(b)), err}
  }
}

export default new CareerUseCase();
