import Vue from 'vue'
import App from './App.vue'
import router from "./route/routes";
import store from "./store/store";
import Meta from "vue-meta";
import SEO from './helpers/SEO';
import lazyLoad from './helpers/Lazyload'
import VueAnalytics from "vue-analytics";
import LottieAnimation from 'lottie-web-vue';

Vue.config.productionTip = false
Vue.directive("lazyload", lazyLoad)
Vue.use(Meta);
Vue.mixin(SEO);
Vue.use(LottieAnimation); // add lottie-animation to your global scope

new Vue({
  render: h => h(App),
  router: router,
  store
}).$mount('#app')

Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_TRACKING_GA,
    router
})
