const state = {
  phoneNumber: '',
  prizes: {},
}

const actions = {
  setPhoneNumber({commit}, payload) {
    commit('SET_PHONE_NUMBER', payload)
  },
  setPrizes({commit}, payload) {
    commit('SET_PRIZES', payload)
  },
}

const mutations = {
  'SET_PHONE_NUMBER'(state, payload) {
    state.phoneNumber = payload
  },
  'SET_PRIZES'(state, payload) {
    state.prizes = payload
  },
}

const getters = {
  GET_PHONE_NUMBER: state => {
    return state.phoneNumber
  },
  GET_PRIZES: state => {
    return state.prizes
  },
}

export default {
  state,
  actions,
  mutations,
  getters
}
