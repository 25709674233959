import TrackRepository from "../../app/api/track";
import TrackUseCase from "../../app/usecase/track.js"

const TrackAPi = new TrackRepository()

const state = {
    tracks: [{track: {track: null}}],
    result_track: [],
    totalSuggestDownload: [],
    suggestDownload: false,
    showCancel: false,
    showAlert: false,
    isLoadingTracking: false,
    listTokopediaShipmentRecommendation: [],
    errTracking: "",
}

const _podMoreThanXDays = (commit, tracking, item) => {
  const findHistory = item.histories.find((history) => history.status === 'POD')
  if (TrackUseCase.isPODMoreThanXDays(20, findHistory?.status_date)) {
      // set to true to showing the modal download our app
      item.is_show_download_app = true
      if (tracking.result_track.length === 1) {
          commit('SET_ALERT_DOWNLOAD_APP', true)
      }
      commit('SET_TOTAL_SUGGEST_DOWNLOAD', true)
      commit('SET_SUGGEST_DOWNLOAD', true)
  } else {
      // set to false to not showing the modal download our app
      item.is_show_download_app = false
      commit('SET_TOTAL_SUGGEST_DOWNLOAD', false)
      commit('SET_SUGGEST_DOWNLOAD', false)
  }
}

const _filterResultTrack = (commit, item, tracking, tokPedIdentifier) => {
  if (item.is_exist) {
      if (item.histories.length) {
          _podMoreThanXDays(commit, tracking, item)
      } else {
          // set to false to not showing the modal download our app
          item.is_show_download_app = false
          commit('SET_TOTAL_SUGGEST_DOWNLOAD', false)
          commit('SET_SUGGEST_DOWNLOAD', false)
      }

  }

  // set true or false if the stt from tokped
  item.is_from_tokopedia = false
  commit('SET_TOKOPEDIA_SHIPMENT_RECOMMENDATION', false)
  if (item.q.toUpperCase().includes(tokPedIdentifier)) {
      item.is_from_tokopedia = item.is_exist
      commit('SET_TOKOPEDIA_SHIPMENT_RECOMMENDATION', true)
      item.is_show_download_app = item.is_exist
      if (tracking.result_track.length === 1 && item.is_exist) {
          commit('SET_ALERT_DOWNLOAD_APP', true)
      }
  }
}

const actions = {
    // new-tracking call this on local component input
    initTrackShipment({commit}, payload) {
        const tokPedIdentifier = "TKP01"
        commit('SET_ALERT_DOWNLOAD_APP', false)
        commit('SET_IS_LOADING_TRACKING', true);
        TrackAPi.getAll(payload.tracks, payload.lang)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    /** @type {TrackingResultData} */
                    const tracking = {
                        tracks: typeof payload.tracks === "string" ? payload.tracks.split(",") : payload.tracks,
                        result_track: res.data.slice(0, 5)
                    }
                    commit('SET_EMPTY_TOKOPEDIA_SHIPMENT_RECOMMENDATION', [])
                    commit('SET_EMPTY_ARRAY_SUGGEST_DOWNLOAD', [])
                    tracking.result_track.forEach(item => {
                        _filterResultTrack(commit, item, tracking, tokPedIdentifier)
                    });
                    commit('GET_TRACK_SHIPMENT', tracking.tracks)
                    commit('GET_RESULT_TRACK_SHIPMENT', tracking.result_track.map((track) => (
                        {...track, histories: track.histories && track.histories.reverse()})));
                } else {
                    throw new Error("something went wrong")
                }
            })
            .catch(err => {
                commit('SET_TRACK_SHIPMENT_ERROR', err.message);
            }).finally(() => {
            commit('SET_IS_LOADING_TRACKING', false);
        })
    },
    closeModalAlert({commit}) {
        commit('SET_ALERT_DOWNLOAD_APP', false)
    },
}

const mutations = {
    'GET_TRACK_SHIPMENT'(state, payload) {
        state.tracks = payload
    },
    'SET_ALERT_DOWNLOAD_APP'(state, payload) {
        state.showAlert = payload
    },
    'SET_TOTAL_SUGGEST_DOWNLOAD'(state, payload) {
        state.totalSuggestDownload.push(payload)
    },
    'SET_EMPTY_ARRAY_SUGGEST_DOWNLOAD'(state, payload) {
        state.totalSuggestDownload = payload
    },
    'GET_RESULT_TRACK_SHIPMENT'(state, payload) {
        state.result_track = payload
    },
    'SET_SUGGEST_DOWNLOAD'(state, payload) {
        state.suggestDownload = payload;
    },
    'SET_IS_LOADING_TRACKING'(state, payload) {
        state.isLoadingTracking = payload
    },
    'SET_TOKOPEDIA_SHIPMENT_RECOMMENDATION'(state, payload) {
        state.listTokopediaShipmentRecommendation.push(payload)
    },
    'SET_EMPTY_TOKOPEDIA_SHIPMENT_RECOMMENDATION'(state, payload) {
        state.listTokopediaShipmentRecommendation = payload
    },
    'SET_TRACK_SHIPMENT_ERROR'(state, payload) {
        state.errTracking = payload
    }
}


const getters = {
    RESULT_TRACK: state => {
        return state.result_track
    },
    TRACKS: state => {
        return state.tracks
    },
    SHOW_ALERT: state => {
        return state.showAlert
    },
    TOTAL_SUGGEST_DOWNLOAD: state => {
        return state.totalSuggestDownload
    },
    SUGGEST_DOWNLOAD: state => {
        return state.suggestDownload;
    },
    IS_LOADING_TRACKING: state => {
        return state.isLoadingTracking
    },
    LIST_TOKOPEDIA_SHIPMENT_RECOMMENDATION: state => {
        return state.listTokopediaShipmentRecommendation
    },
    RESULT_TRACK_ERROR: state => {
        return state.errTracking
    },
}


export default {
    state,
    actions,
    getters,
    mutations
}
