import CustomerAppAPI from '../customerAppAxios';
import '../type';

/**
 * @borrows InternalAPI
 */
export default class RouteOrderRepository extends CustomerAppAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = 'v1/shipment/masterdata/route/origin';
    /** @type {string} */
    this.endpointReverse = 'v1/shipment/maps/reverse';
  }

  /**
   *
   * @param input {String}
   * @return {Promise<{data: RouteOrder[], err: Error}>}
   * @private
   */
  async _fetchData(input) {
    try {
      const res = await this.$axios.get(`${this.endpoint}?keyword=${input}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data.data, err: null };
        }
      }
      return { data: res.data.data, err: null };
    } catch (err) {
      if (err.response && err.response.data) {
        return { data: [], err: this.setErrorMessage(err.response.data.message) };
      }
      return { data: [], err: this.setErrorMessage(err.message) };
    }
  }

  /**
   *
   * @return {Promise<{data: RouteOrder[], err: Error}>}
   */
  async getDefaultAll() {
    return this._fetchData('');
  }

  /**
   *
   * @param inputSearch {string}
   * @return {Promise<{data: RouteOrder[], err: Error}>}
   */
  async getAllBySearch(inputSearch) {
    return this._fetchData(inputSearch);
  }

  /**
   *
   * @param lat {string}
   * @param long {string}
   * @returns {Promise<{data: AddressDetailsReverse|null, err: Error}>}
   */
  async getReverseRoute(lat, long) {
    try {
      const res = await this.$axios.get(`${this.endpointReverse}?lat=${lat}&long=${long}`);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      if (err.response && err.response.data) {
        return { data: null, err: this.setErrorMessage(err.response.data.message) };
      }
      return { data: null, err: this.setErrorMessage(err.message) };
    }
  }
}
