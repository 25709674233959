import router from '../../route/routes.js';
const state = {
    agent_id: null,
    is_agent_from_ads: false
}

const actions = {
    setAgentId({commit}, payload) {
        commit('SET_AGENT_ID', payload)
    },
    setIsAgentFromSubmit({commit}, payload) {
        commit('SET_IS_AGENT_FROM_SUBMIT', payload)
    },
    setAgentIdNew({commit}, payload) {
        commit('SET_AGENT_ID_NEW', payload)
    },
}

const mutations = {
    'SET_AGENT_ID'(state, payload) {
        if (payload) {
            router.replace({
                name: "successRegisterAgent",
                query: { q: payload }
            });
        }
        state.agent_id = payload
    },
    'SET_IS_AGENT_FROM_SUBMIT'(state, payload) {
        state.is_agent_from_ads = payload
    },
    'SET_AGENT_ID_NEW'(state, payload) {
        if (payload) {
            router.replace({
                name: "Pendaftaran berhasil",
                query: { q: payload }
            });
        }
        state.agent_id = payload
    },
}

const getters = {
    AGENT_ID: state => {
        return state.agent_id
    },
    GET_IS_AGENT_FROM_SUBMIT: state => {
        return state.is_agent_from_ads
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}