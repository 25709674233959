<template>
  <div id="container-app">
    <div
      v-if="load"
      id="loader-lp"
    >
      <div id="loader-lp-content">
        <img
          :src="LPLogo"
          alt="lp.svg"
          width="250px"
        >
      </div>
    </div>
    <div
      v-else
      id="animation-on"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import LPLogo from "@/assets/lp_logo_red.svg";
import Liona from "@/assets/Liona.webp";

export default {
  name: "GeneralLayout",
  data() {
    return {
      LPLogo,
      load: true,
      deletedID: false,
      Liona,
      showLiona: true
    }
  },
  computed: {
    styleApp() {
      return this.$route.path !== "/" ? {
        position: "",
        paddingTop: "36px",
      } : {
        position: "relative",
        paddingTop: "0",
      }
    }
  },
  created() {
    document.addEventListener("readystatechange", this.initApp)
  },
  beforeDestroy() {
    document.removeEventListener("readystatechange", this.initApp)
  },
  methods: {
    initApp(e) {
      if (e.target.readyState === 'interactive') {
        this.load = true
      } else if (e.target.readyState === 'complete') {
        setTimeout(() => {
          this.load = false
          this.removeAnimationOnElID()
        }, 500)
      }
    },
    removeAnimationOnElID() {
      setTimeout(() => {
        const animationOnElement = document.querySelector("#animation-on")
        if(animationOnElement){
          animationOnElement.removeAttribute("id")
        }
      }, 1000)
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/css/reset.scss";

#container-app {
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.2px;
  box-sizing: border-box;
  padding: 0;
  margin: 0 auto;
}

#main-nav {
  height: 0;
  position: fixed;
  width: 100%;
  z-index: 1001;
}

#loader-lp {
  height: 100vh;
}

#loader-lp-content {
  text-align: center;
  line-height: 100vh;
  margin: 0;
}

#loader-lp-content img {
  animation: dum 2s infinite ease-in-out;
}

@keyframes dum {
  0% {
    transform: scale(0.8)
  }
  100% {
    transform: scale(1.1)

  }
}

#animation-on {
  animation: bummer 1s;
  -webkit-animation: bummer 1s;
  -moz-animation: bummer 1s;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

@-webkit-keyframes bummer {
  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bummer {
  100% {
    -moz-transform: scale(1);
  }
}

@keyframes bummer {
  100% {
    transform: scale(1);
  }
}

.app-space {
  padding-top: 2%;
}

@media (min-width: 900px) {
  .app-space {
    padding-top: 4%;
  }
}

#wa-cs {
  position: fixed;
  right: 0 !important;
  bottom: 0;
  padding-right: 10px;
  z-index: 500;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  > a div {
    &:first-child {
      width: 140px;

      @media (max-width: 599px) {
        width: 80px;
      }
    }
  }
}
</style>
