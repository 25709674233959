import '../type/checkPriceAlgo';
import CustomerAppAPI from '../customerAppAxios';

export default class CheckShippingPriceAlgoRepository extends CustomerAppAPI {
    constructor() {
        super();
        /** @type {string} */
        this.version = 'v5';
        this.endpoint = 'shipment/elexys/tariff';
        this.endpointBulky = 'v1/shipment/tariff'
    }

    /**
     *
     * @param payload {RequestCheckPriceAlgo}
     * @returns {Promise<{data: null, err: *}|{data: Array<ResponseCheckPriceAlgo>, err: null}|{data: null, err: null}>}
     */
    async requestShippingPrice(payload) {
        payload.is_new_cod = payload.is_dfod || payload.is_new_cod
        try {
            /** @type {import('axios').AxiosResponse<Object>} */
            const res = await this.$axios.post(`${this.version}/${this.endpoint}`, payload);
            if (res.status === 200) {
                if (res.data) {
                    return {data: res.data.data, err: null};
                }
            }
            return {data: null, err: null};
        } catch (err) {
            return {data: null, err: this.setErrorMessage(err)};
        }
    }

    /**
     *
     * @param payload {{data: RequestCheckPriceBulkyAlgo[], is_new_cod: boolean}}
     * @returns {Promise<{data: ResponseBulkyCheckPriceAlgo[] | null, total: null, parcel_point: null, err: *}>}
     */
    async bulkyCheckShippingPrice(payload) {
        payload.is_new_cod = true
        try {
            /** @type {import('axios').AxiosResponse<Object>} */
            const res = await this.$axios.post(`${this.endpointBulky}/bulk`, payload);
            if (res.status === 200) {
                if (res.data) {
                    return {
                        data: res.data.data,
                        total: res.data.total_ongkir,
                        parcel_point: res.data.parcel_point,
                        err: null
                    };
                }
            }
            return {data: null, total: null, parcel_point: null, err: null};
        } catch (err) {
            return {data: null, total: null, parcel_point: null, err: this.setErrorMessage(err)};
        }
    }
}
