/**
 * @enum {String}
 * @type {{AT_DESTINATION_TO: (STATUS|String), ON_PROCESS: (STATUS|String), FAILED: (STATUS|String),
 * IN_TRANSIT: (STATUS|String), WITH_COURIER: (STATUS|String), AT_DESTINATION_FROM: (STATUS|String),
 * FINISH: (STATUS|String)}}
 */
export const STATUS = {
    FINISH: {value: 6, name: "pod", title: "", src: "pod"},
    WITH_COURIER: {value: 5, name: "del", title: "On Process", src: "with-courier"},
    AT_DESTINATION_TO: {value: 4, name: "sti_dest", title: "On Process", src: "at-destination"},
    IN_TRANSIT: {value: 3, name: "ecargo", title: "On Process", src: "in-transit"},
    AT_DESTINATION_FROM: {value: 2, name: "sti", title: "On Process", src: "at-destination"},
    ON_PROCESS: {value: 1, name: "bkd", title: "On Process", src: "on-process"},
    FAILED: {value: 0, name: "cnx", title: "", src: "cnx"},
}

/**
 * @enum {String}
 * @type {{PROCESS: string, CANCEL: string, ERROR: string, EMPTY: string}}
 */
export const WARNING_STATE = {
    PROCESS: "PROCESS",
    CANCEL: "CANCEL",
    EMPTY: "EMPTY",
    ERROR: "ERROR"
}
