export default {
  methods: {
    SEO : (
      data
    ) => {
      const _removeTag = [
        'meta[name="description"]',
        'meta[name="keywords"]',
        'meta[property="og:title"]',
        'meta[property="og:description"]',
        'meta[property="article:tag"]',
        'meta[property="article:published_time"]',
        'meta[property="og:url"]',
        'meta[property="og:image"]',
        'meta[name="twitter:title"]',
        'meta[name="twitter:description"]',
        'meta[name="twitter:image:src"]'
      ];

      _removeTag.forEach((v) => {
        if (document.querySelector(v) !== null) {
          document.querySelector(v).remove();
        }
      });

      return { 
        // main SEO
        title: data.title,
        meta: [
          // main SEO
          {
            name: 'description',
            content: data.description
          },
          {
            name: 'keywords',
            content: data.keywords
          },
          // OG Facebook
          {
            property: 'og:title',
            content: data.title
          },
          {
            property: 'og:description',
            content: data.description
          },
          {
            property: 'article:tag',
            content: data.keywords
          },
          {
            property: 'article:section',
            content: data.channel
          },
          {
            property: 'article:published_time',
            content: data.publishedTime
          },
          {
            property: 'og:url',
            content: window.location.href
          },
          {
            property: 'og:image',
            content: data.image
          },
          // Twitter Card
          {
            name: 'twitter:title',
            content: data.title
          },
          {
            name: 'twitter:description',
            content: data.description
          },
          {
            name: 'twitter:image:src',
            content: data.image
          }
        ],
        link: [
          {
            rel: 'canonical',
            href: window.location.href
          }
        ]
      }
    }
  }
}