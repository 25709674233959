import checkShippingPriceAlgo from "@/app/usecase/checkShippingPriceAlgo";
import routeAlgo from "@/app/usecase/routeOrder";
import "@/app/type/checkPriceAlgo";
import "@/app/type";
import {ORDER_LOCAL_STORAGE_KEY} from "@/app/constant/order";

/** @type {string[]} */
const filtersPickUpOrigin = [
    "é=e",
    "kota bks=kota bekasi",
    "south tarogong=tarogong kidul",
    "sanankulon=sanan kulon",
    "daerah=",
    "khusus=",
    "ibukota=",
    "ibu kota=",
    "kota=",
    "city=",
    "special=",
    "istimewa=",
    "region=",
    "kabupaten=",
    "kab=",
    "sub-district=",
    "kecamatan=",
    "kec=",
    "regency="
]


const state = {
    typeOder: 'DROPOFF',
    isLoadingCheckPrice: false,
    isLoadingCheckPricePage: false,
    sender: {
        lastCoordinate: {},
        name: "",
        email: "",
        phoneNumber: "",
        phoneCode: "+62",
        service: "dropoff",
        subdistrict: [],
        address: "",
        referencePoint: "",
        routeId: null
    },
    /**
     * @type SenderDataOrder
     */
    senderForm: {
        lastCoordinate: {},
        name: "",
        email: "",
        phoneNumber: "",
        phoneCode: "+62",
        phoneCodeId: 17,
        service: "dropoff",
        subdistrict: [],
        pickupAddress: "",
        pickupSubDistrict: "",
        dropoffAddress: "",
        referencePoint: "",
        origin: "",
        dropoffOrigin: "",
    },
    recipient: {
        name: "",
        phoneNumber: "",
        phoneCode: "+62",
        benchmark: "",
        address: "",
        addressLabel: "rumah",
        destination: [],
        id: 1,
    },
    recipientChooseProduct: null,
    validateRecipient: {
        name: "",
        phoneNumber: null,
        address: "",
    },
    validatePackage: {
        commodity: null,
        weight: null,
    },
    validateDropShipper: {
        isSelected: false,
        name: "",
        phoneNumber: "",
        isValid: false,
    },
    pickUpOrigin: {
        district: "",
        subDistrict: "",
        routeId: 0,
    },
    /** @type {PickUpSchedule}*/
    pickUpSchedules: {
        route_id: 0,
        dynamic_pickup_id: 0,
        is_disabled_today: false,
        is_disabled_tomorrow: false,
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
    },
    package: {
        commodity: [],
        quantity: [{
            label: '1',
            value: 1,
        }],
        weight: 1,
        length: 0,
        width: 0,
        height: 0,
        value: 0,
        insurance: true,
        fragile: false,
        cod: false,
        dfod: false,
        productType: "",
    },
    errorCheckPrice: null,
    errorBulkyCheckPrice: null,
    /**
     * @type {Recipient[]}
     */
    recipientsPackage: [],
    responseCheckPrice: [],
    isAddNewRecipient: false,
    editIndex: -1,
    listOfErrorsBulkyCheckPrice: [],
    listReceiverProduct: [],
    triggerAddNewRecipient: false,
    isPickupBoost: false,
    pickupBoostValue: 0
}

const actions = {
    setPickupBoostValue({ commit }, payload) {
        commit('SET_PICKUP_BOOST_VALUE', payload)
        localStorage.setItem('pickupBoostValue', payload)
    },
    setPickupBoost({ commit }) {
        commit('SET_IS_PICKUP_BOOST', true)
        localStorage.setItem('isPickupBoost', true)
    },
    removePickupBoost({ commit }) {
        commit('SET_IS_PICKUP_BOOST', false)
        localStorage.setItem('isPickupBoost', false)
        commit('SET_PICKUP_BOOST_VALUE', 0)
        localStorage.setItem('pickupBoostValue', 0)
    },
    setCleanRecipientPackage({commit}) {
        const currentState = state.recipientsPackage
        const newState = []
        // reset index recipient
        currentState.forEach((recipient) => {
            if (recipient.destination.length > 0 && recipient.choicePackage) {
                recipient.index = newState.length + 1
                newState.push(recipient)
            }
        })

        newState.push({
            isPackageCOD: false,
            isValidDropshipper: false,
            dropshipperName: "",
            dropshipperPhoneNumber: "",
            index: newState.length + 1,
            name: "",
            phoneNumber: "",
            address: "",
            destination: [],
            referencePoint: "",
            labelPoint: "",
            packageCommodity: "",
            isPackageFreagile: false,
            packageQuantity: 1,
            packageWeight: 1,
            packageWidth: 0,
            packageLength: 0,
            packageHeight: 0,
            packageValue: 0,
            isPackageInsurance: false,
            isPackageDFOD: false
        })
        commit('CLEAN_RECIPIENT_PACKAGE', newState)
    },
    setTypeOrder({commit}, payload) {
        commit('SET_TYPE_ORDER', payload)
        localStorage.setItem('typeOrder', JSON.stringify(payload))
    },
    setSender({commit}, payload) {
        commit('SET_SENDER', payload)
        localStorage.setItem('sender', JSON.stringify(payload))
    },
    setSenderForm({commit}, payload) {
        commit('SET_SENDER_FORM', payload)
        localStorage.setItem('senderForm', JSON.stringify(payload))
    },
    setPackage({commit}, payload) {
        commit('SET_PACKAGE', payload)
    },
    // call this to get the origin of the sender
    async setSenderPickUpOrigin({commit, dispatch, state}, payload) {
        if (!payload.lat || !payload.lng) return
        return await routeAlgo.getReverseRoute(payload.lat, payload.lng).then(async res => {
            const payloadPickUpOrigin = {
                district: res.data.district,
                subDistrict: res.data.sub_district,
                routeId: 0,
            }
            for (const filter of filtersPickUpOrigin) {
                let [key, value] = filter.split('=')
                res.data.district = res.data.district.toLowerCase().replace(new RegExp(key, 'g'), value).trim()
                res.data.sub_district = res.data.sub_district.toLowerCase().replace(new RegExp(key, 'g'), value).trim()
                payloadPickUpOrigin.district = res.data.district
                payloadPickUpOrigin.subDistrict = res.data.sub_district
            }
            await routeAlgo.getAllBySearch(`${payloadPickUpOrigin.subDistrict}, ${payloadPickUpOrigin.district}`).then(resRoute => {
                if (resRoute.data.length > 0) {
                    // choose first index because based on the search result on the top
                    payloadPickUpOrigin.district = resRoute.data[0].city
                    payloadPickUpOrigin.subDistrict = resRoute.data[0].route
                    payloadPickUpOrigin.routeId = resRoute.data[0].route_id
                }
                commit('SET_PICKUP_ORIGIN', payloadPickUpOrigin)
                dispatch('setSenderForm', {...state.senderForm, origin: payloadPickUpOrigin.subDistrict})
                dispatch('setSender', {...state.sender, origin: payloadPickUpOrigin.subDistrict, routeId: payloadPickUpOrigin.routeId})
            })
        })
    },
    setRecipient({commit}, payload) {
        commit('SET_RECIPIENT', payload)
    },
    async setCheckPrice({commit, state}) {
        if (state.recipient.destination === undefined || state.recipient.destination.length === 0
            || (state.package.weight > 1 && (state.package.length === 0 || state.package.width === 0 || state.package.height === 0)) || state.package.commodity.length === 0) {
            return
        }

        commit('SET_LOADING_CHECK_PRICE_PAGE', true)
        commit('SET_ERROR_CHECK_PRICE', null)
        /**
         * @type {RequestCheckPriceAlgo}
         */
        const req = {
            "check_tariff_page": false,
            "cod_value": state.package.cod ? state.package.value : 0,
            "commodity": state.package.commodity.length > 0 ? state.package.commodity[0].valueData : '',
            "destination": state.recipient.destination.length > 0 ? state.recipient.destination[0].value : '',
            "goods_value": state.package.value,
            "height": state.package.height,
            "is_insurance": state.package.insurance,
            "length": state.package.length,
            "origin": state.typeOder == 'DROPOFF' ? state.senderForm.dropoffOrigin : `${state.senderForm.origin}`,
            "page_type": !state.package.dfod && !state.package.cod ? state.typeOder.toLowerCase() : "cod",
            "shipment_type": state.typeOder,
            "weight": parseFloat(state.package.weight),
            "width": state.package.width,
            "is_wood_package": state.package.fragile,
            "is_dfod": state.package.dfod,
        }
        await checkShippingPriceAlgo.requestShippingPrice(req).then(res => {
            if (res.err) {
                let errMsg = res.err
                if (errMsg.includes('Data tidak ditemukan')) errMsg = "Kota tujuanmu mengalami masalah operasional sementara. Silahkan pilih kota lainnya."
                commit('SET_ERROR_CHECK_PRICE', errMsg)
            }
            commit('SET_RESPONSE_CHECK_PRICE', res.data || [])
        }).catch(err => {
            commit('SET_ERROR_CHECK_PRICE', err)
            commit('SET_RESPONSE_CHECK_PRICE', [])
        }).finally(() => commit('SET_LOADING_CHECK_PRICE_PAGE', false))
    },
    setBulkyCheckPrice({commit}) {
        /**
         * @type {RequestCheckPriceBulkyAlgo[]}
         */
        const reqs = []
        commit('SET_LOADING_CHECK_PRICE', true)
        commit('SET_LIST_ERROR_BULKY_CHECK_PRICE', [])
        state.recipientsPackage.forEach((recipient, index) => {
            /** @type {Recipient} */
            const dataRecipient = recipient
            if (dataRecipient.destination.length !== 0 && dataRecipient.choicePackage !== undefined) {
                /** @type {RequestCheckPriceBulkyAlgo} */
                const req = {
                    "check_tariff_page": false,
                    "cod_value": dataRecipient.isPackageCOD ? dataRecipient.packageValue : 0,
                    "commodity": dataRecipient.packageCommodity.length > 0 ? dataRecipient.packageCommodity[0].valueData : '',
                    "goods_value": dataRecipient.packageValue,
                    "is_insurance": dataRecipient.isPackageInsurance || false,
                    "height": dataRecipient.packageHeight || 0,
                    "id": index + 1,
                    "is_dfod": dataRecipient.isPackageDFOD,
                    "is_new_cod": dataRecipient.isPackageCOD || dataRecipient.isPackageDFOD,
                    "length": dataRecipient.packageLength,
                    "destination": dataRecipient.destination[0].value,
                    "origin": state.typeOder == 'DROPOFF' ? state.senderForm.dropoffOrigin : `${state.senderForm.origin}`,
                    "page_type": !dataRecipient.isPackageCOD && !dataRecipient.isPackageDFOD ? "reguler" : "cod",
                    "product_type": dataRecipient.choicePackage.product_type.toUpperCase(),
                    "shipment_type": state.typeOder,
                    "weight": dataRecipient.packageWeight,
                    "width": dataRecipient.packageWidth,
                    "is_wood_package": dataRecipient.isPackageFreagile
                }
                reqs.push(req)
            }
        })
        const dataRequest = {
            data: reqs,
            is_new_cod: true
        }
        commit('SET_ERROR_BULKY_CHECK_PRICE', null)
        checkShippingPriceAlgo.bulkyCheckShippingPrice(dataRequest).then(res => {
            if (res.err) {
                commit('SET_ERROR_BULKY_CHECK_PRICE', res.err)
            }

            const listBulkyError = []
            const allRecipient = [...state.recipientsPackage]
            res.data.forEach((dataResp) => {
                const currenIndex = allRecipient.findIndex((recipient) => {
                    return recipient.index === dataResp.id
                })
                if (currenIndex !== -1 && !('error' in dataResp)) {
                    allRecipient[currenIndex].choicePackage.price = dataResp.result.price
                    allRecipient[currenIndex].choicePackage.priceBase = dataResp.result.price_base
                    allRecipient[currenIndex].codFee = dataResp.result.cod_fee
                    allRecipient[currenIndex].insuranceFee = dataResp.result.insurance_rate
                }

                if (currenIndex !== -1 && ('error' in dataResp)) {
                    allRecipient[currenIndex].choicePackage.price = 0
                    allRecipient[currenIndex].choicePackage.priceBase = 0
                    listBulkyError.push(`Pengiriman ${allRecipient[currenIndex].name} ${dataResp.error.message.id}`)
                }
            })

            //set error if service = pickup and product_type = jumbopack & interpack
            let containJumbopack = false;
            let containInterpack = false;
            if (allRecipient) containJumbopack = allRecipient.some(v => v.choicePackage.product_type.toLowerCase() === 'jumbopack')
            if (allRecipient) containInterpack = allRecipient.some(v => v.choicePackage.product_type.toLowerCase() === 'interpack')
            if (state.senderForm.service.toLowerCase() === 'pickup' && containJumbopack) listBulkyError.push(`Pengiriman pickup tidak mendukung product jumbopack`)
            if (state.senderForm.service.toLowerCase() === 'pickup' && containInterpack) listBulkyError.push(`Pengiriman pickup tidak mendukung product interpack`)
            
            commit('SET_RECIPIENT_PACKAGE', allRecipient)
            commit('SET_LIST_ERROR_BULKY_CHECK_PRICE', listBulkyError)

        }).catch(err => {
            commit('SET_ERROR_BULKY_CHECK_PRICE', err)
        }).finally(() => commit('SET_LOADING_CHECK_PRICE', false))
    },
    setRecipientPackage({commit}, payload) {
        commit('SET_RECIPIENT_PACKAGE', payload)
    },
}

const mutations = {
    'SET_PICKUP_BOOST_VALUE' (state, payload) {
        state.pickupBoostValue = payload;
    },
    'SET_IS_PICKUP_BOOST' (state, payload) {
        state.isPickupBoost = payload;
    },
    'SET_TYPE_ORDER'(state, payload) {
        state.typeOder = payload
    },
    'SET_SENDER'(state, payload) {
        state.sender = payload
    },
    'SET_SENDER_FORM'(state, payload) {
        state.senderForm = payload
    },
    'SET_PICKUP_ORIGIN'(state, payload) {
        state.pickUpOrigin = payload
    },
    'SET_PICKUP_SCHEDULE'(state, payload) {
        state.pickUpSchedules = payload
    },
    'SET_RECIPIENT'(state, payload) {
        localStorage.setItem(ORDER_LOCAL_STORAGE_KEY.RECIPIENT, JSON.stringify(payload))
        state.recipient = payload
    },
    'SET_ERROR_RECIPIENT'(state, payload) {
        state.validateRecipient[payload.key] = payload['value']
    },
    'SET_VALIDATE_DROP_SHIPPER'(state, payload) {
        state.validateDropShipper[payload.key] = payload['value']
    },
    'SET_PACKAGE'(state, payload) {
        state.package = payload
        localStorage.setItem(ORDER_LOCAL_STORAGE_KEY.PACKAGE, JSON.stringify(payload))
    },
    'ADD_EMPTY_RECIPIENT_PACKAGE'(state) {
        state.recipientsPackage.push({
            isPackageCOD: false,
            isValidDropshipper: false,
            dropshipperName: "",
            dropshipperPhoneNumber: "",
            index: state.recipientsPackage.length + 1,
            name: "",
            phoneNumber: "",
            phoneCodeId: "",
            address: "",
            addressLabel: "rumah",
            destination: [],
            referencePoint: "",
            labelPoint: "",
            packageCommodity: "",
            isPackageFreagile: false,
            packageQuantity: 1,
            packageWeight: 1,
            packageWidth: 0,
            packageLength: 0,
            packageHeight: 0,
            packageValue: 0,
            isPackageInsurance: false,
            isPackageDFOD: false
        })


        localStorage.removeItem(ORDER_LOCAL_STORAGE_KEY.RECIPIENT)
        localStorage.removeItem(ORDER_LOCAL_STORAGE_KEY.PACKAGE)

    },
    /**
     *
     * @param state
     * @param payload {Recipient[]}
     */
    'SET_RECIPIENT_PACKAGE'(state, payload) {
        if (payload.length >= 1) {
            localStorage.setItem(ORDER_LOCAL_STORAGE_KEY.RECIPIENT_PACKAGE, JSON.stringify(payload))
        }
        state.recipientsPackage = payload
    },
    'CLEAN_RECIPIENT_PACKAGE'(state, currentPayload) {
        const currentState = currentPayload
        const newState = []
        // reset index recipient
        currentState.forEach((recipient) => {
            if (recipient.destination.length > 0 && recipient.choicePackage) {
                recipient.index = newState.length + 1
                newState.push(recipient)
            }
        })
        
        state.recipientsPackage = newState
        if (newState.length > 0) {
            localStorage.setItem(ORDER_LOCAL_STORAGE_KEY.RECIPIENT_PACKAGE, JSON.stringify(state.recipientsPackage))
        }
    },
    /**
     *
     * @param state
     * @param index {number}
     */
    'DELETE_RECIPIENT_PACKAGE'(state, index) {
        state.recipientsPackage = state.recipientsPackage.filter(val => val.index !== index)
        // reset index
        state.recipientsPackage.forEach((val, index) => val.index = index + 1)
        localStorage.setItem(ORDER_LOCAL_STORAGE_KEY.RECIPIENT_PACKAGE, JSON.stringify(state.recipientsPackage))
    },
    'SET_LOADING_CHECK_PRICE'(state, payload) {
        state.isLoadingCheckPrice = payload
    },
    'SET_LOADING_CHECK_PRICE_PAGE'(state, payload) {
        state.isLoadingCheckPricePage = payload
    },
    'SET_RESPONSE_CHECK_PRICE'(state, payload) {
        state.isLoadingCheckPrice = false
        state.responseCheckPrice = payload
    },
    'SET_ADD_NEW_RECIPIENT'(state, isNew) {
        if (isNew) {
            state.recipient = {
                name: "",
                phoneNumber: "",
                phoneCode: "+62",
                benchmark: "",
                address: "",
                addressLabel: "rumah",
                destination: [],
            }
            state.package = {
                commodity: [],
                quantity: [{
                    label: '1',
                    value: 1,
                }],
                weight: 1,
                length: 0,
                width: 0,
                height: 0,
                value: 0,
                insurance: false,
                fragile: false,
                cod: false,
                dfod: false,
                productType: "",
            }
        }
        state.isAddNewRecipient = isNew
    },
    SET_RECIPIENT_CHOOSE_PRODUCT(state, payload) {
        state.recipientChooseProduct = payload
    },
    SET_ERROR_CHECK_PRICE(state, payload) {
        state.errorCheckPrice = payload
    },
    SET_ERROR_BULKY_CHECK_PRICE(state, payload) {
        state.errorBulkyCheckPrice = payload
    },
    SET_EDIT_RECIPIENT_PACKAGE_INDEX(state, payload) {
        localStorage.setItem(ORDER_LOCAL_STORAGE_KEY.EDIT_INDEX, JSON.stringify(payload))
        state.editIndex = payload
    },
    SET_LIST_ERROR_BULKY_CHECK_PRICE(state, payload) {
        state.listOfErrorsBulkyCheckPrice = payload;
    },
    SET_LIST_RECEIVER_PRODUCT(state, payload) {
        state.listReceiverProduct = payload
    },
    SET_TRIGGER_ADD_NEW_RECIPIENT(state, payload) {
        state.triggerAddNewRecipient = payload
    }
}

const getters = {
    GET_PICKUP_BOOST_VALUE: (state) => {
        return state.pickupBoostValue;
    },
    IS_PICKUP_BOOST: (state) => {
        return state.isPickupBoost;
    },
    TYPE_ORDER: state => {
        return state.typeOder
    },
    SENDER: state => {
        return state.sender
    },
    SENDER_FORM: state => {
        return state.senderForm
    },
    GET_RECIPIENT: state => {
        return state.recipient
    },
    GET_PACKAGE: state => {
        return state.package
    },
    GET_RECIPIENT_PACKAGE: state => {
        return state.recipientsPackage
    },
    GET_RECIPIENT_PACKAGE_HAS_PRODUCT: state => {
      return state.recipientsPackage.filter((item) => item.destination.length > 0 && item.choicePackage);
    },
    GET_RECIPIENT_PACKAGE_HAS_PRODUCT_COD: state => {
      return state.recipientsPackage.filter((item) => {
          return item.destination.length > 0 && item.choicePackage && (item.isPackageCOD || item.isPackageDFOD)
      });
    },
    GET_LOADING_PRICE_PRODUCT: state => {
        return state.isLoadingCheckPrice
    },
    GET_LOADING_PRICE_PRODUCT_PAGE: state => {
        return state.isLoadingCheckPricePage
    },
    GET_PICKUP_ORIGIN: state => {
        return state.pickUpOrigin
    },
    GET_PICKUP_SCHEDULE: state => {
        return state.pickUpSchedules
    },
    GET_RESPONSE_CHECK_PRICE: state => {
        return state.responseCheckPrice
    },
    GET_ADD_NEW_RECIPIENT: state => {
        return state.isAddNewRecipient
    },
    GET_VALIDATE_RECIPIENT: state => {
        return state.validateRecipient
    },
    GET_RECIPIENT_CHOOSE_PRODUCT: state => {
        return state.recipientChooseProduct
    },
    GET_CURRENT_RECIPIENT_DATA_VALID: (state) => {
        const isFilledRecipient = state.recipient.name.trim().length > 0 && state.recipient.phoneNumber !== '' && state.recipient.address.trim().length > 0
        const isValidRecipient = Object.values(state.validateRecipient).every((val) => val === null) && isFilledRecipient
        const isValidMainRecipient = isValidRecipient && state.recipient.destination.length > 0
        const isValidCommodity = state.package.commodity.length > 0
        const isValidWeight = parseFloat(state.package.weight) === 1 || (parseFloat(state.package.weight) > 1 && parseFloat(state.package.width) > 0 && parseFloat(state.package.length) > 0 && parseFloat(state.package.height) > 0)
        const isValidValue = ((state.package.value > 0 && state.package.insurance) || !state.package.insurance)
        const isValidPackage = isValidCommodity && isValidWeight && isValidValue
        const isValidDropShipper = state.validateDropShipper.isSelected ? state.validateDropShipper.isValid : true
        const isValidGoodsValue = state.errorCheckPrice === null || state.errorCheckPrice === ''
        return isValidMainRecipient && isValidPackage && isValidDropShipper && isValidGoodsValue
    },
    GET_ERROR_CHECK_PRICE: state => {
        return state.errorCheckPrice
    },
    GET_ERROR_BULKY_CHECK_PRICE: state => {
        return state.errorBulkyCheckPrice
    },
    GET_EMPTY_RECIPIENT_PACKAGE: state => {
        return state.recipient.name === '' && state.recipient.phoneNumber === '' && state.recipient.address.trim().length === 0
    },
    GET_EDIT_RECIPIENT_PACKAGE_INDEX: state => {
        return state.editIndex
    },
    GET_CURRENT_SENDER_FORM_DATA_VALID: (state) => {
        const isFilledSender = state.senderForm.name.trim().length > 2 && state.senderForm.phoneNumber !== ''
        if (state.senderForm.service == 'pickup') {
            const isValidLastCoordinate = JSON.stringify(state.senderForm.lastCoordinate) !== JSON.stringify({})
            return isFilledSender && isValidLastCoordinate
        } else {
            const isValidDropoff = state.senderForm.subdistrict.length !== 0
            return isFilledSender && isValidDropoff
        }
    },
    GET_LIST_ERROR_BULKY_CHECK_PRICE: (state) => {
        return state.listOfErrorsBulkyCheckPrice
    },
    GET_VALIDATE_DROPSHIPPER: state => {
        return state.validateDropShipper
    },
    GET_LIST_RECEIVER_PRODUCT: state => {
        return state.listReceiverProduct.map(item => item.value)
    },
    GET_TRIGGER_ADD_NEW_RECIPIENT: state => {
        return state.triggerAddNewRecipient
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}
