export const ORDER_LOCAL_STORAGE_KEY = {
    'RECIPIENT': 'recipientForm',
    'RECIPIENT_PACKAGE': 'recipientPackage',
    'SENDER': 'sender',
    'SENDER_FORM': 'senderForm',
    'TYPE_ORDER': 'typeOrder',
    'PICKUP_ORIGIN': 'pickupOrigin',
    'PACKAGE': 'package',
    'INVOICES': 'orderInvoices',
    'EDIT_INDEX': 'editIndex',
}