import career from "@/pages/new-career/app/usecase/career";

const state = {
  filter: {
    city: [],
    division: [],
    type: [],
    search: '',
  },
  cities: [],
  divisions: [],
  types: ['Full Time', 'Part Time', 'Intern'],
  career: {},
};

const actions = {
  async setLocation({ commit }) {
    const location = await career.getLocation()
    commit('SET_LOCATION', location?.data || []);
  },
  async setDivision({ commit }) {
    const division = await career.getDivision()
    commit('SET_DIVISION', division?.data || []);
  },
  setFilter({ commit }, payload) {
    commit('SET_FILTER', payload);
  },
  resetFilter({ commit }) {
    commit('RESET_FILTER');
  },
  setCareer({ commit }, payload) {
    commit('SET_CAREER', payload);
  },
};

const mutations = {
  SET_LOCATION(state, payload) {
    state.cities = payload;
  },
  SET_DIVISION(state, payload) {
    state.divisions = payload;
  },
  SET_FILTER(state, payload) {
    state.filter[payload.key] = payload.value;
  },
  RESET_FILTER(state) {
    state.filter = {
      search: '',
      division: [],
      type: [],
      city: [],
    };
  },
  SET_CAREER(state, payload) {
    state.career = payload;
  },
};

const getters = {
  FILTER(state) {
    return state.filter;
  },
  DIVISIONS(state) {
    return mapOptionsData(state.divisions);
  },
  CITIES(state) {
    return mapOptionsData(state.cities);
  },
  TYPES(state) {
    return mapOptionsData(state.types);
  },
  CAREER(state) {
    return state.career;
  },
};

const mapOptionsData = options => {
  return options.map(option => {
    return {
      label: option,
      value: option,
    };
  });
};

export default {
  state,
  actions,
  mutations,
  getters
}
