import axios from 'axios';
// import {ProductName} from "@/app/enum";

// const productSort = {
//     bosspack: {
//         name: ProductName.BOSSPACK.toLowerCase(),
//             index: 0,
//     },
//     regpack: {
//         name: ProductName.REGPACK.toLowerCase(),
//             index: 1,
//     },
//     jagopack: {
//         name: ProductName.JAGOPACK.toLowerCase(),
//             index: 2,
//     },
//     jumbopack: {
//         name: ProductName.JUMBOPACK.toLowerCase(),
//             index: 3,
//     },
//     bigpack: {
//         name: ProductName.BIGPACK.toLowerCase(),
//             index: 4,
//     },
//     //uncomment jika mau tampilkan otopack
//     // otopack150: {
//     //   name: ProductName.OTOPACK150.toLowerCase(),
//     //   index: 5,
//     // },
//     // otopack250: {
//     //   name: ProductName.OTOPACK250.toLowerCase(),
//     //   index: 6,
//     // },
// }

const state = {
    productId: 0,
    products: [],
    isLoading: false,
    product: {},
    error: null,
}

const mutations = {
    'SET_PRODUCTS'(state, payload) {
        state.products = payload
    },
    'SET_LOADING'(state, payload) {
        state.isLoading = payload
    },
    'SET_PRODUCT'(state, payload) {
        state.product = payload
    },
    'SET_ERROR'(state, payload) {
        state.error = payload
    }
}

const getters = {
    GET_PRODUCTS: state => {
        return state.products
    },
    IS_LOADING_PRODUCTS: state => {
        return state.isLoading
    },
    GET_PRODUCT: state => {
        return state.product
    },
    GET_ERROR_FETCH_PRODUCTS: state => {
        return state.error
    }

}

const actions = {
    getProductList({ commit }) {
        let url = process.env.VUE_APP_DOMAIN_URL;
        commit('SET_ERROR', null)
        commit('SET_LOADING', true)
        axios.get(
            `${url}/v1/product_detail/data?page=1&per_page=20&status=ACTIVE&q=`,
            {
                headers: {
                    Authorization: process.env.VUE_APP_TOKEN_URL
                }
            }
        ).then(res => {
            const sorted = res.data.data.sort((a, b) => {
                if (a.product_id > b.product_id) {
                    return 1
                } else {
                    if (b.product_id > a.product_id) {
                        return -1
                    } else {
                        return 0
                    }
                }
            })
            commit('SET_PRODUCTS', sorted)
        }).catch(err => {
            console.log(err)
            commit('SET_ERROR', err)
            alert(err.response.data.message.id)
            commit('SET_PRODUCTS', [])
        }).finally(() => {
            commit('SET_LOADING', false)
        })
    },
    getProductDetail({ commit }, id) {
        let url = process.env.VUE_APP_DOMAIN_URL;
        commit('SET_LOADING', true)
        axios.get(`${url}/v1/product_detail/data/${id}`,
            {
                headers: {
                    Authorization: process.env.VUE_APP_TOKEN_URL
                }
            }
        ).then(res => {
            commit('SET_PRODUCT', res.data)
        }).catch((err) => {
            alert(err.response.data.message.id)
            commit('SET_PRODUCT', {});
        }).finally(() => {
            commit('SET_LOADING', false)
        })
    }

}

export default {
    state,
    mutations,
    actions,
    getters
}
