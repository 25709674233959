import RouteOrderRepository from '../api/routeOrder';
import '../type';
import { changeWordToTitle } from '@/misc/stringMapper';

class RouteOrderUseCase {
  constructor() {
    /** @type {RouteOrderRepository} */
    this.api = new RouteOrderRepository();
    this.rgxFindBracet = /\(([^)]+)\)/g;
  }

  /**
   *
   * @return {Promise<{data: RouteOrder[], err: Error}>}
   */
  async getAllDefault() {
    const {data, err} = await this.api.getDefaultAll();
    if (data.length > 0) {
      data.forEach((val) => {
        val.route = changeWordToTitle(`${val.route}`);
      });
    }
    return {data, err};
  }

  /**
   *
   * @param input {string}
   * @return {Promise<{data: RouteOrder[], err: Error}>}
   */
  async getAllBySearch(input) {
    input = input.replace(this.rgxFindBracet, '').trim();
    const {data, err} = await this.api.getAllBySearch(input);
    if (data.length > 0) {
      data.forEach((val) => {
        val.route = changeWordToTitle(val.route);
      });
    }
    return {data, err};
  }

    /**
     *
     * @param lat {string}
     * @param long {string}
     * @returns {Promise<{data: AddressDetailsReverse|null, err: Error}>}
     */
    async getReverseRoute(lat, long) {
      return this.api.getReverseRoute(lat, long);
    }
}

export default new RouteOrderUseCase();
