import axios from 'axios';
const state = {
    idPos: '',
    resultPos: [],


}

const actions = {
    initResultPos({ commit }, payload) {
        let pos = process.env.VUE_APP_DOMAIN_URL;
        axios
            .get(
                `${pos}/v1/network/pos/data?page=1&per_page=50&sub_district_id=${payload.idPos}`,
                {
                    headers: {
                        Authorization: process.env.VUE_APP_TOKEN_URL
                    }
                }
            )
            .then(res => {
                if (res.status === 200) {
                    const pos = {
                        resultPos: res.data,
                        idPos: payload.idPos
                    }
                    commit('SET_RESULT_POS', pos);
                    window.scrollTo(0, 300);
                }
            })
            .catch(err => {
               alert( err.response.data.message.id)
               if (err.response.data.message.id.includes("tidak tersedia")) {
                   commit('SET_EMPTY_RESULT_POS', [])
               }
            });
    },
}

const mutations = {
    'SET_RESULT_POS'(state, payload) {
        state.resultPos = payload.resultPos
        state.idPos = payload.idPos
    },
    'SET_EMPTY_RESULT_POS'(state, payload) {
        state.resultPos = payload
    }
}

const getters = {
    RESULT_NPOS: state => {
        return state.resultPos
    },
    ID_POS: state => {
        return state.idPos
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}